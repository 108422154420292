import React, { Component } from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
import './App.css';
// import BeerCards from './containers/BeerCards';
// import BeerBaseStats from './components/BeerbaseStats/BeerBaseStats';
// import Header from './components/Navigation/Header/Header';
// import Footer from './components/Navigation/Footer/footer';
// import AddBeer from './containers/AddBeer';

import 'bootstrap/dist/css/bootstrap.min.css';


class App extends Component {
  render() {
    return (
      <div className="App">
        {/* <Header />
        <Container className="containers">
            <Row>
              <Col xs={12} md={3}><AddBeer /></Col>
              <Col xs={12} md={7}><BeerCards /></Col>
              <Col xs={12} md={2}><BeerBaseStats /></Col>
            </Row>
        </Container>
        <Footer /> */}
        <h1>BEERBASE.NET</h1>
        <br/>
        <h5>BUILDING A GREAT WEBSITE...</h5>
        <br/>
        <h5>All about beer, beer and beer!</h5>
      </div>
    );
  }
}

export default App;
